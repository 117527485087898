
<template>
    <div>
            <v-container class="my-2">
                    <v-layout row wrap px-0 mb-4>
                        <v-flex xs9>
                            <v-breadcrumbs class="px-0" :items="$store.getters.language.data.team_members.list_path">
                                <template v-slot:item="{ item }">
                                    <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                        {{ item.title.toUpperCase() }}
                                    </v-breadcrumbs-item>
                                </template>
                            </v-breadcrumbs>
                        </v-flex>
                        <v-flex xs3>
                           
                        </v-flex>
                    </v-layout>
                <form @submit.prevent="addTeamMembers" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_name_ku" type="text" :label="$store.getters.language.data.team_members.team_member_name_ku" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_name_en" type="text" :label="$store.getters.language.data.team_members.team_member_name_en" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_name_ar" type="text" :label="$store.getters.language.data.team_members.team_member_name_ar" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_index" type="number" :label="$store.getters.language.data.team_members.team_member_index" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_text_ku" type="textarea" :label="$store.getters.language.data.team_members.team_member_text_ku" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_text_en" type="textarea" :label="$store.getters.language.data.team_members.team_member_text_en" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_text_ar" type="textarea" :label="$store.getters.language.data.team_members.team_member_text_ar" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_image" type="textarea" :label="$store.getters.language.data.team_members.team_member_image" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.team_members.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card flat>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers"    :search="search" :items="rows" class="elevation-0"
                    item-key="team_member_id">
                    <template v-slot:[`item.team_member_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/team_members-list/'+item.team_member_id"  color="teal" class="mx-1">
                                <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on" >
                                        <v-icon> mdi-delete-outline </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        {{$store.getters.language.data.team_members.delete_question}}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text class="elevation-0">
                                            {{$store.getters.language.data.team_members.cancel_btn}}
                                        </v-btn>
                                        <v-btn color="error" class="elevation-0" @click="deleteTeamMembers(item.team_member_id)">
                                            {{$store.getters.language.data.team_members.yes_btn}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
        </v-card>
    </v-flex>
</v-layout>
</v-container>

<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/team_members.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    export default {
        data() {
            return {
                team_members: {},
                search: '',
                loading : true,
                loading_btn:false,
                
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                
                selected_team_members : {},
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_name_ku,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_name_ku',
                        },
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_name_en,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_name_en',
                        },
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_name_ar,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_name_ar',
                        },
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_index,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_index',
                        },
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_text_ku,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_text_ku',
                        },
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_text_en,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_text_en',
                        },
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_text_ar,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_text_ar',
                        },
                        { 
                            text: this.$store.getters.language.data.team_members.team_member_image,
                            align: 'start',
                            sortable: true,
                            value: 'team_member_image',
                        },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'team_member_id',
        }
                ],
            }
        },
        computed: {
            page(){
                const pageKey = 'TEAMMEMBERS'
                return {}
            }
        },
        mounted(){
            this.readTeamMembers();
        },
        methods: {
            exportExcel(){
                const list = this.rows
                let result = []
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    let obj = {team_member_id : item.team_member_id,team_member_name_ku : item.team_member_name_ku,team_member_name_en : item.team_member_name_en,team_member_name_ar : item.team_member_name_ar,team_member_index : item.team_member_index,team_member_text_ku : item.team_member_text_ku,team_member_text_en : item.team_member_text_en,team_member_text_ar : item.team_member_text_ar,team_member_image : item.team_member_image,
                    }
                    result.push(obj)
                }
				var f = xlsx.utils.json_to_sheet(result)
				var wb = xlsx.utils.book_new()
				xlsx.utils.book_append_sheet(wb, f, 'sheet')
				xlsx.writeFile(wb, result.length+' rows of '+'TeamMembers.xlsx')
            },
            addTeamMembers() {
                this.loading_btn = true
                requests.createTeamMembers(this.team_members).then(r => {
                    if (r.status == 200) {
                        this.team_members = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'TeamMembers Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add TeamMembers',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteTeamMembers(team_member_id) {
                requests.deleteTeamMembers(team_member_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.team_member_id != team_member_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'TeamMembers Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readTeamMembers() {
                this.loading = true
                requests.getAllTeamMembers().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.team_members
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read TeamMembers',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read TeamMembers',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectTeamMembers(i) {
                this.selected_team_members = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    